import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout activeGroup={null} secondHeaderText={null}>
    <SEO title="Home" />
    <h1 className="text-3xl font-bold text-gray-900">Most used developer tools in one place</h1>
  </Layout>
)

export default IndexPage
